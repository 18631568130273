<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Value List</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="600">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Value List Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <!--Value List Category-->
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.valueListsCategories"
                    label="Value List"
                    placeholder=" "
                    persistent-placeholder
                    item-text="d_name"
                    item-value="id"
                    v-model="fk_valueListID"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <!--Name-->
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_name"
                    label="Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <!--Value-->
              <v-row dense>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="d_value"
                    label="Value"
                    type="number"
                    min="0"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <!--Color-->
              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="d_color"
                    label="Color"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <!--Sort-->
              <v-row dense>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="d_sort"
                    label="Sort"
                    type="number"
                    min="0"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <!--Status-->
              <v-row dense>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "valueListsStore/getEntryField",
  mutationType: "valueListsStore/updateEntryField"
});

export default {
  name: "ValueListEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",
      "fk_valueListID",
      "d_name",
      "d_value",
      "d_sort",
      "d_color",
      "f_status"
    ])
  },
  data() {
    return {};
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("valueListsStore/resetEntry");
    },

    async newEntry() {
      await this.$store.dispatch("valueListsStore/categories");

      this.resetEntry();

      this.mode = 1;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["valueListsStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("valueListsStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("valueListsStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
