<template>
  <div>
    <!--Title Bar-->
    <title-bar :title-value="'Value Lists'" />

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span></v-btn
          >
        </template>

        <v-card width="400px">
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>

                <!--Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_name"
                    label="List Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <!--Entry Dialog-->
      <value-list-entry />
    </v-toolbar>

    <!--Pagination-->
    <pagination
      :show="valueListsStore.valueLists.data && valueListsStore.valueLists.data.length > 0"
      :showTotal="true"
      :currentPage="valueListsStore.valueLists.current_page"
      :lastPage="valueListsStore.valueLists.last_page"
      :total="valueListsStore.valueLists.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <v-list>
        <v-list-group v-for="item in valueListsStore.valueLists.data" :key="item.id" no-action>
          <v-list-item slot="activator">
            <v-list-item-content>
              <v-list-item-title
                ><span class="grey--text"> ({{ item.id }})</span>
                <span class="text-body-1">{{ item.d_name }}</span></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.id"
            @click="openEntry(subItem.id)"
          >
            <v-list-item-content>
              <v-list-item-title
                ><span class="grey--text"> ({{ subItem.id }})</span>
                <span class="text-body-1"
                  >{{ subItem.d_name }} - {{ subItem.d_value }}</span
                  >
                  <span right :class="
                    subItem.f_status === 1 || subItem.f_status === true
                      ? 'primary--text'
                      : 'error--text'
                  " style="float:right">
                  {{$_activeStatus(subItem.f_status)}}</span
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ListBasic from "@/components/ListBasic";
import ValueListEntry from "@/components/ValueListEntry";

const { mapFields } = createHelpers({
  getterType: "valueListsStore/getFilterField",
  mutationType: "valueListsStore/updateFilterField"
});

export default {
  name: "ValueLists",
  mixins: [mixin],
  components: {
    TitleBar,
    ValueListEntry,
    Pagination,
    ListBasic
  },
  computed: {
    ...mapState({
      valueListsStore: state => state.valueListsStore,
      progressStore: state => state.progressStore
    }),
    ...mapFields(["page", "d_name"])
  },
  data() {
    return {
      filterMenu: false
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch("progressStore/set", true);
      /* Grab filters */
      const filters = this.$store.getters["valueListsStore/getFilters"];
      /* Records based on filters */
      await this.$store.dispatch("valueListsStore/fetch", filters);
      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      await this.$store.dispatch("valueListsStore/categories");
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.page = 1;
      this.d_name = "";
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (this.d_name) {
        return true;
      }
      return false;
    },

    async openEntry(id) {
      await this.loadValueLists();
      await this.$store.dispatch("valueListsStore/entry", id);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
